
import { StepperComponent } from "@/assets/ts/components";
import FamiliaInfo from "@/components/FamiliaInfo.vue";
import Step1 from "@/components/wizard/familiaRegistrada/Step1.vue";
import Step2 from "@/components/wizard/familiaRegistrada/Step2.vue";
import { Actions } from "@/store/enums/StoreEnums";
import ServiciosProgramadosModule from "@/store/modules/ServiciosProgramadosModule";
import { ServicioMenor } from "@/types";
import { ElMessage } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useForm } from "vee-validate";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { mapGetters, useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface IStep1 {
  menores: ServicioMenor[];
}

interface IStep2 {
  politicaPrivacidad: boolean;
  declaracionResponsable: boolean;
}

interface CreateAccount extends IStep1, IStep2 {}

export default defineComponent({
  name: "kt-horizontal-wizard",
  components: {
    Step1,
    Step2,
    FamiliaInfo,
  },

  setup() {
    const { t, te } = useI18n();
    const _stepperObj = ref<StepperComponent | null>(null);
    const horizontalWizardRefs = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    const route = useRoute();
    const token = route.query.act;

    const formData = ref<CreateAccount>({
      menores: [],
      politicaPrivacidad: false,
      declaracionResponsable: false,
    });

    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);

    onBeforeMount(() => {
      if (token) {
        module.fetchById({ formularioId: token as string });
      }
    });
    const datosServicio = computed(() => module.servicio);
    const datosContacto = computed(() => module.contacto);
    const comarcaId = ref<string>();
    const nombreActividad = ref<string>();

    watch(datosServicio, () => {
      if (!module.contacto) {
        const pathFijo = new String("/#/home?act=");
        const pathVariable = token || "";
        window.location.href = pathFijo.concat(pathVariable.toString());
        return;
      }
      if (!datosServicio.value) return;

      comarcaId.value = datosServicio.value.comarcaId;
      nombreActividad.value = datosServicio.value.nombre;
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        horizontalWizardRefs.value as HTMLElement
      );
    });

    const i18n = (text) => (te(text) ? t(text) : text);

    const createAccountSchema = [
      Yup.object({
        menores: Yup.array().min(1, i18n("errorFaltaMenor")),
      }),
      Yup.object({
        politicaPrivacidad: Yup.boolean().isTrue(
          i18n("politicaPrivacidadValidacion")
        ),
        declaracionResponsable: Yup.boolean().isTrue(
          i18n("declaracionResponsableValidacion")
        ),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<IStep1 | IStep2>({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }
      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      if (
        totalSteps.value != null &&
        currentStepIndex.value + 1 >= totalSteps.value
      ) {
        return formSubmit(formData.value);
      }

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = async (formData) => {
      let codigo;
      if (token) {
        try {
          if (datosContacto.value) {
            formData.dni = datosContacto.value.dni;
            formData.telefono = datosContacto.value.telefono;
            const contacto = { dni: "", telefono: "" };
            module.setContacto(contacto);
          }
          let menores = formData.menores;
          formData.servicioProgramadoId = token;
          formData.comarcaId = comarcaId.value;
          for (let menor of menores) {
            formData.menores = [menor];
            await store
              .dispatch(Actions.POST_ACTIVIDAD, formData)
              .then((codActividad) => {
                codigo = codActividad;
              });
          }
        } catch (err) {
          console.error("Err", err);
          console.log("20231003 - FamiliaRegistrada");
          return ElMessage.error(t("formularioEnviadoError"));
        }

        Swal.fire({
          html: t("formularioActividadEnviadoBody", { codigo }),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: i18n("cerrarBtn"),
          customClass: {
            confirmButton: "btn btn-lg btn-primary",
          },
        }).then(() => {
          window.location.href = "/#/home";
        });
      } else {
        if (datosContacto.value) {
          formData.dni = datosContacto.value.dni;
          formData.telefono = datosContacto.value.telefono;
          const contacto = { dni: "", telefono: "" };
          module.setContacto(contacto);
        }
        formData.comarcaId = comarcaId.value;
        store
          .dispatch(Actions.POST_FAMILIAS, formData)
          .then((codigo) => {
            Swal.fire({
              html: t("formularioEnviadoBody", { codigo }),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: i18n("cerrarBtn"),
              customClass: {
                confirmButton: "btn btn-lg btn-primary",
              },
            }).then(() => {
              window.location.href = "/#/home";
            });
          })
          .catch((err) => {
            console.error("Err", err);
            ElMessage.error(t("formularioEnviadoError"));
          });
      }
    };

    return {
      horizontalWizardRefs,
      previousStep,
      handleStep,
      totalSteps,
      currentStepIndex,
      token,
      comarcaId,
      nombreActividad,
    };
  },
  computed: {
    ...mapGetters(["isPendingForm"]),
  },
});
