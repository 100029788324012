
import { defineComponent } from "vue";
import { ServicioMenor } from "@/types";
import { format } from "date-fns";

export default defineComponent({
  name: "iam-menores-list",
  components: {},
  props: {
    list: Object as () => ServicioMenor[],
    onItemTap: Function,
  },
  setup() {
    return {
      format,
    };
  },
});
