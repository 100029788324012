
import { defineComponent } from "vue";
import Menores from "@/components/widgets/lists/Menores.vue";
import DatosMenorModal from "@/components/modals/DatosMenor.vue";
import DatosMenorSimpleModal from "@/components/modals/DatosMenorSimple.vue";
import { ServicioMenor } from "@/types";
import { ErrorMessage, useField } from "vee-validate";
import { hideModal, showModal } from "@/core/helpers/dom";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "step-3",
  components: {
    Menores,
    ErrorMessage,
    DatosMenorModal,
    DatosMenorSimpleModal,
  },
  data() {
    return {
      menorData: {},
      isCreate: false,
      isSimple: this.token ? true : false,
    };
  },
  setup() {
    const { value: list, errorMessage } = useField<ServicioMenor[]>("menores");
    const route = useRoute();
    const token = route.query.act;

    return { list, errorMessage, token };
  },
  methods: {
    create() {
      this.isCreate = true;
      this.reset();
      this.$nextTick(() => {
        this.isSimple
          ? showModal(document.getElementById("iam_modal_datos_menor_simple"))
          : showModal(document.getElementById("iam_modal_datos_menor"));
      });
    },
    edit(model) {
      this.isCreate = false;
      this.menorData = Object.assign({}, model);
      this.$nextTick(() => {
        this.isSimple
          ? showModal(document.getElementById("iam_modal_datos_menor_simple"))
          : showModal(document.getElementById("iam_modal_datos_menor"));
      });
    },
    saveOrUpdate(menor) {
      if (this.isCreate) {
        this.list.push({ ...menor });
      } else {
        const index = this.list.findIndex((ele) => ele.id === menor.id);
        this.list[index] = menor;
      }

      this.isSimple
        ? hideModal(document.getElementById("iam_modal_datos_menor_simple"))
        : hideModal(document.getElementById("iam_modal_datos_menor"));

      this.reset();
    },
    reset() {
      this.isSimple
        ? (this.menorData = {
            id: new Date().getTime(),
            nombre: "",
            apellidos: "",
            fechaNacimiento: null,
          })
        : (this.menorData = {
            id: new Date().getTime(),
            nombre: "",
            apellidos: "",
            fechaNacimiento: null,
            lugarPrestacion: "",
            lugarEspacioColectivo: "",
            otrosEspacioColectivo: "",
            direccion: "",
            inicioPrevisto: null,
            finPrevisto: null,
            detalleNecesidades: "",
            tiempos: [{ dias: [], horas: [] }],
          });
    },
  },
  mounted() {
    this.reset();
  },
});
