
import tratamientos from "@/core/data/tratamiento";
import { hideModal } from "@/core/helpers/dom";
import { ServicioMenorSimple } from "@/types";
import { isDate, parse } from "date-fns";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { defineComponent, ref } from "vue";
import FlatPickr from "vue-flatpickr-component";
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";

export default defineComponent({
  name: "datos-menor-modal",
  components: {
    ErrorMessage,
    FlatPickr,
  },
  props: {
    callback: Function,
    title: String,
    model: Object as () => ServicioMenorSimple,
  },
  data() {
    return {
      configFechaNacimiento: {
        disableMobile: true,
        dateFormat: "d/m/Y",
        locale: Spanish,
        maxDate: "today",
      },
    };
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const myModalEl = document.getElementById("iam_modal_datos_menor_simple");
    if (myModalEl) {
      myModalEl.addEventListener("show.bs.modal", () => {
        self.resetForm({ values: self.model });
      });
    }
  },

  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);

    const { t, te } = useI18n();
    const i18n = (text) => (te(text) ? t(text) : text);

    const validationSchema = Yup.object({
      id: Yup.number().required(),
      tratamiento: Yup.string().required().label(i18n("TratamientoLabel")),
      nombre: Yup.string().required().label(i18n("menoresModalNombreLabel")),
      apellidos: Yup.string()
        .required()
        .label(i18n("menoresModalApellidosLabel")),
      fechaNacimiento: Yup.date()
        .typeError(i18n("errorFaltaFecha"))
        .transform((v, o) =>
          !isDate(o) ? parse(o, "dd/MM/yyyy", new Date()) : o
        )
        .max(new Date())
        .required(),
      menorId: Yup.string(),
    });

    const { resetForm, handleSubmit, errors } = useForm<ServicioMenorSimple>({
      validationSchema,
    });

    resetForm({
      values: props.model,
    });

    const submit = handleSubmit((values) => {
      const data = {
        ...values,
        fechaNacimiento: parse(
          `${values.fechaNacimiento}`,
          "dd/MM/yyyy",
          new Date()
        ),
      };

      props.callback && props.callback(data);
      hideModal(modalRef.value);
      resetForm();
    });

    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: fechaNacimiento } = useField("fechaNacimiento");

    return {
      tratamientos,
      tratamiento,
      nombre,
      resetForm,
      apellidos,
      fechaNacimiento,
      modalRef,
      submitButtonRef,
      submit,
      validationSchema,
      errors,
    };
  },
});
