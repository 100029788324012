
import Combobox from "@/components/widgets/Combobox.vue";
import lugaresEspaciosColectivos from "@/core/data/lugaresEspaciosColectivos";
import lugaresPrestacion from "@/core/data/lugaresPrestacion";
import { dias, horarios } from "@/core/data/tiemposFamilias";
import tratamientos from "@/core/data/tratamiento";
import { hideModal } from "@/core/helpers/dom";
import { ServicioMenor, Tiempo } from "@/types";
import { isDate, parse, startOfDay } from "date-fns";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { defineComponent, ref, watch } from "vue";
import FlatPickr from "vue-flatpickr-component";
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";

export default defineComponent({
  name: "datos-menor-modal",
  components: {
    ErrorMessage,
    Combobox,
    FlatPickr,
  },
  props: {
    callback: Function,
    title: String,
    model: Object as () => ServicioMenor,
  },
  data() {
    return {
      configFechaNacimiento: {
        disableMobile: true,
        dateFormat: "d/m/Y",
        locale: Spanish,
        maxDate: "today",
      },
      configInicioPrevisto: {
        disableMobile: true,
        dateFormat: "d/m/Y",
        locale: Spanish,
        minDate: startOfDay(new Date()),
        maxDate: null,
      },
      configFinPrevisto: {
        disableMobile: true,
        dateFormat: "d/m/Y",
        locale: Spanish,
        minDate: null,
      },
    };
  },

  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const myModalEl = document.getElementById("iam_modal_datos_menor");
    if (myModalEl) {
      myModalEl.addEventListener("show.bs.modal", () => {
        self.resetForm({ values: self.model });
      });
    }
  },
  methods: {
    onStartChange(selectedDates, dateStr) {
      this.configFinPrevisto.minDate = dateStr;
    },
    onEndChange(selectedDates, dateStr) {
      this.configInicioPrevisto.maxDate = dateStr;
    },
    addInput() {
      this.tiempos.push({ dias: [], horas: [] });
    },
    removeInput(index) {
      if (this.tiempos.length > 1) {
        this.tiempos.splice(index, 1);
      } else {
        this.tiempos = [{ dias: [], horas: [] }];
      }
    },
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);

    const { t, te } = useI18n();
    const i18n = (text) => (te(text) ? t(text) : text);

    const validationSchema = Yup.object({
      id: Yup.number().required(),
      tratamiento: Yup.string().required().label(i18n("TratamientoLabel")),
      nombre: Yup.string().required().label(i18n("menoresModalNombreLabel")),
      apellidos: Yup.string()
        .required()
        .label(i18n("menoresModalApellidosLabel")),
      fechaNacimiento: Yup.date()
        .typeError(i18n("errorFaltaFecha"))
        .transform((v, o) =>
          !isDate(o) ? parse(o, "dd/MM/yyyy", new Date()) : o
        )
        .max(new Date())
        .required(),
      lugarPrestacion: Yup.string()
        .required()
        .label(i18n("lugarPrestacionMenorLabel")),
      lugarEspacioColectivo: Yup.string()
        .when("lugarPrestacion", {
          is: "ESPACIO",
          then: Yup.string().required(),
          otherwise: Yup.string(),
        })
        .label(i18n("lugarEspacioColectivoMenorLabel")),
      otrosEspacioColectivo: Yup.string()
        .when("lugarEspacioColectivo", {
          is: "OTROS",
          then: Yup.string().required(),
          otherwise: Yup.string(),
        })
        .label(i18n("lugarEspacioColectivoMenorLabel")),
      direccion: Yup.string()
        .required()
        .label(i18n("menoresModalDireccionLabel")),
      inicioPrevisto: Yup.date()
        .typeError(i18n("errorFaltaFecha"))
        .transform((v, o) =>
          !isDate(o) ? parse(o, "dd/MM/yyyy", new Date()) : o
        )
        .min(startOfDay(new Date()))
        .required(),
      finPrevisto: Yup.date()
        .typeError(i18n("errorFaltaFecha"))
        .transform((v, o) =>
          !isDate(o) ? parse(o, "dd/MM/yyyy", new Date()) : o
        )
        .min(Yup.ref("inicioPrevisto"))
        .required(),
      detalleNecesidades: Yup.string()
        .required()
        .label(i18n("menoresModalDetallesPrestacion")),
      tiempos: Yup.array()
        .of(
          Yup.object({
            dias: Yup.array().ensure().min(1),
            horas: Yup.array().ensure().min(1),
          })
        )
        .ensure()
        .min(1)
        .test(
          "elemento-completo",
          i18n("errorFaltaDiaHora"),
          (value) =>
            !value?.some((ele) => !ele?.dias?.length || !ele?.horas?.length)
        ),
    });

    const { resetForm, handleSubmit } = useForm<ServicioMenor>({
      validationSchema,
    });

    resetForm({
      values: props.model,
    });

    const submit = handleSubmit((values) => {
      const data = {
        ...values,
        fechaNacimiento: parse(
          `${values.fechaNacimiento}`,
          "dd/MM/yyyy",
          new Date()
        ),
        inicioPrevisto: parse(
          `${values.inicioPrevisto}`,
          "dd/MM/yyyy",
          new Date()
        ),
        finPrevisto: parse(`${values.finPrevisto}`, "dd/MM/yyyy", new Date()),
        tiempos: values.tiempos.map((tiempo) => {
          return {
            dias: tiempo.dias.map((dia) => parseInt(dia, 10)),
            horas: tiempo.horas.map((hora) => parseInt(hora, 10)),
          };
        }),
      };

      props.callback && props.callback(data);
      hideModal(modalRef.value);
      resetForm();
    });

    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: fechaNacimiento } = useField("fechaNacimiento");
    const { value: lugarPrestacion } = useField("lugarPrestacion");
    const {
      value: lugarEspacioColectivo,
      resetField: lugarEspacioColectivoReset,
    } = useField("lugarEspacioColectivo");
    const {
      value: otrosEspacioColectivo,
      resetField: otrosEspacioColectivoReset,
    } = useField("otrosEspacioColectivo");
    const { value: direccion } = useField("direccion");
    const { value: inicioPrevisto } = useField("inicioPrevisto");
    const { value: finPrevisto } = useField("finPrevisto");
    const { value: detalleNecesidades } = useField("detalleNecesidades");
    const { value: tiempos } = useField<Array<Tiempo>>("tiempos");

    watch(lugarPrestacion, () => {
      if (lugarPrestacion.value === "DOMICILIO") {
        lugarEspacioColectivoReset();
        otrosEspacioColectivoReset();
      }
    });

    return {
      tratamientos,
      tratamiento,
      nombre,
      resetForm,
      apellidos,
      fechaNacimiento,
      lugarPrestacion,
      lugarEspacioColectivo,
      otrosEspacioColectivo,
      direccion,
      inicioPrevisto,
      finPrevisto,
      detalleNecesidades,
      modalRef,
      submitButtonRef,
      submit,
      validationSchema,
      lugaresPrestacion,
      lugaresEspaciosColectivos,
      tiempos,
      dias,
      horarios,
    };
  },
});
